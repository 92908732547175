<template>
  <el-card class="box-card" shadow="always">
    <div slot="header" class="clearfix">
      <span>{{ title }}</span>
      <el-button-group style="float: right;">
        <el-button size="mini" icon="el-icon-back" round @click="showCard">返回</el-button>
      </el-button-group>
    </div>
    <div class="body-wrapper">
      <el-form ref="form" :model="form" label-width="100px" size="mini">
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.fieldName')" prop="columnName">
              <el-input v-model="form.columnName" disabled>
                <el-button v-hasPerm="['metadata:changerecord:add']" slot="append" icon="el-icon-edit-outline" @click="changeRecord('columnName')" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.fieldComment')" prop="columnComment">
              <el-input v-model="form.columnComment" disabled>
                <el-button v-hasPerm="['metadata:changerecord:add']" slot="append" icon="el-icon-edit-outline" @click="changeRecord('columnComment')" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.dataDefault')" prop="dataDefault">
              <el-input v-model="form.dataDefault" disabled>
                <el-button v-hasPerm="['metadata:changerecord:add']" slot="append" icon="el-icon-edit-outline" @click="changeRecord('dataDefault')" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.isPrimaryKey')" prop="columnKey">
              <el-input v-model="form.columnKey === '1' ? 'Y' : 'N'" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.isEmpty')" prop="columnNullable">
              <el-input v-model="form.columnNullable === '1' ? 'Y' : 'N'" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.dataType')" prop="dataType">
              <el-input v-model="form.dataType" disabled />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.dataLength')" prop="dataLength">
              <el-input v-model="form.dataLength" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.dataPrecision')" prop="dataPrecision">
              <el-input v-model="form.dataPrecision" disabled />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.datacolumn.field.dataScale')" prop="dataScale">
              <el-input v-model="form.dataScale" disabled />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-divider content-position="center">变更记录</el-divider>
      <el-row>
        <el-col :span="24">
          <el-table
            :data="changeData.dataList"
            stripe
            border
            :max-height="200"
            style="width: 100%; margin: 15px 0;"
          >
            <el-table-column label="序号" align="center">
              <template slot-scope="scope">
                <span>{{ scope.$index + 1 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="sourceName" :label="$t('cip.dc.datacolumn.field.sourceName')" align="center" show-overflow-tooltip />
            <el-table-column prop="tableName" :label="$t('cip.dc.datacolumn.field.tableName')" align="center" show-overflow-tooltip />
            <el-table-column prop="fieldName" :label="$t('cip.dc.datacolumn.field.updateField')" align="center" show-overflow-tooltip :formatter="fieldNameFormatter" />
            <el-table-column prop="version" :label="$t('cip.dc.datacolumn.field.version')" align="center" show-overflow-tooltip />
            <el-table-column prop="fieldOldValue" :label="$t('cip.dc.datacolumn.field.fieldOldValue')" align="center" show-overflow-tooltip />
            <el-table-column prop="fieldNewValue" :label="$t('cip.dc.datacolumn.field.fieldNewValue')" align="center" show-overflow-tooltip />
          </el-table>
          <el-pagination
            :page-sizes="[10, 20, 50, 100]"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page.sync="changeData.pageNum"
            :page-size.sync="changeData.pageSize"
            :total="changeData.dataTotal"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </el-col>
      </el-row>
    </div>

    <!-- 变更弹框 -->
    <el-dialog :title="record.title" :visible.sync="record.open" min-width="600px" append-to-body>
      <el-form ref="form2" :model="form2" :rules="rules2" label-width="80px">
        <el-form-item :label="$t('cip.dc.datacolumn.field.updateField')" prop="fieldName">
          <el-input v-model="form2.fieldName" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datacolumn.field.updateField')" :disabled="true" />
        </el-form-item>
        <el-form-item :label="$t('cip.dc.datacolumn.field.version')" prop="version">
          <el-input v-model="form2.version" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datacolumn.field.version')" />
        </el-form-item>
        <el-form-item :label="$t('cip.dc.datacolumn.field.fieldOldValue')" prop="fieldOldValue">
          <el-input v-model="form2.fieldOldValue" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datacolumn.field.fieldOldValue')" :disabled="true" />
        </el-form-item>
        <el-form-item :label="$t('cip.dc.datacolumn.field.fieldNewValue')" prop="fieldNewValue">
          <el-input v-model="form2.fieldNewValue" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datacolumn.field.fieldNewValue')" />
        </el-form-item>
        <el-form-item :label="$t('cip.dc.datacolumn.field.status')" prop="status">
          <el-radio-group v-model="form2.status">
            <el-radio
              v-for="dict in statusOptions"
              :key="dict.id"
              :label="dict.itemText"
            >{{ dict.itemValue }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('cip.dc.datacolumn.field.remark')" prop="remark">
          <el-input v-model="form2.remark" type="textarea" :placeholder="$t('cip.cmn.rule.inputWarning')+$t('cip.dc.datacolumn.field.remark')" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitRecordForm">{{$t('submitText')}}</el-button>
        <el-button @click="record.open = false">{{$t('cancelText')}}</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { getDataColumn } from '@/api/metadata/datacolumn'
import { pageChangeRecord, addChangeRecord } from '@/api/metadata/changerecord'
import { getDicts } from '@/api/dict'

export default {
  name: 'DataColumnDetail',
  props: {
    data: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      title: '元数据详情',
      // 展示切换
      showOptions: {
        data: {},
        showList: true,
        showDetail: false
      },
      // 表单参数
      form: {},
      changeData: {
        dataList: [],
        pageNum: 1,
        pageSize: 20,
        dataTotal: 0
      },
      dicts: new Map([
        ['columnName', this.$t('cip.dc.datacolumn.field.fieldName')],
        ['columnComment', this.$t('cip.dc.datacolumn.field.columnComment')],
        ['dataDefault', this.$t('cip.dc.datacolumn.field.dataDefault')],
        ['columnKey', this.$t('cip.dc.datacolumn.field.isPrimaryKey')],
        ['columnNullable', this.$t('cip.dc.datacolumn.field.isEmpty')],
        ['dataType', this.$t('cip.dc.datacolumn.field.dataType')],
        ['dataLength', this.$t('cip.dc.datacolumn.field.dataLength')],
        ['dataPrecision', this.$t('cip.dc.datacolumn.field.dataPrecision')],
        ['dataScale', this.$t('cip.dc.datacolumn.field.dataScale')]
      ]),
      record: {
        // 是否显示弹出层
        open: false,
        // 弹出层标题
        title: this.$t('cip.dc.datacolumn.field.ysjbg')
      },
      form2: {
        status: '1'
      },
      rules2: {
        fieldName: [
          { required: true, message: this.$t('cip.dc.datacolumn.field.fieldName')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        fieldOldValue: [
          { required: true, message: this.$t('cip.dc.datacolumn.field.fieldOldValue')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        fieldNewValue: [
          { required: true, message: this.$t('cip.dc.datacolumn.field.fieldNewValue')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ],
        version: [
          { required: true, message: this.$t('cip.dc.datacolumn.field.version')+this.$t('datacenter.form.notEmpty'), trigger: 'blur' }
        ]
      },
      // 状态数据字典
      statusOptions: []
    }
  },
  created() {
    console.log('id:' + this.data.id)
    getDicts('sys_common_status').then(res => {
      let response = res.data;
      if (response.success) {
        this.statusOptions = response.data
      }
    })
  },
  mounted() {
    this.getDataColumn(this.data.id)
    this.getChangeRecordList()
  },
  methods: {
    showCard() {
      this.$emit('showCard', this.showOptions)
    },
    /** 获取详情 */
    getDataColumn: function(id) {
      getDataColumn(id).then(res => {
        let response = res.data;
        if (response.success) {
          this.form = response.data
        }
      })
    },
    handleSizeChange(val) {
      this.changeData.pageNum = 1
      this.changeData.pageSize = val
      this.getChangeRecordList()
    },
    handleCurrentChange(val) {
      this.changeData.pageNum = val
      this.getChangeRecordList()
    },
    getChangeRecordList() {
      const data = {}
      data.objectId = this.data.id
      data.pageNum = this.changeData.pageNum
      data.pageSize = this.changeData.pageSize
      pageChangeRecord(data).then(res => {
        let response = res.data;
        if (response.success) {
          const { data } = response
          this.changeData.dataList = data.data
          this.changeData.dataTotal = data.total
        }
      })
    },
    fieldNameFormatter(row, column, cellValue, index) {
      return this.dicts.get(cellValue)
    },
    changeRecord(field) {
      this.record.open = true
      this.form2.objectId = this.data.id
      this.form2.fieldName = field
      this.form2.fieldOldValue = this.form[field]
      this.form2.version = 1
    },
    submitRecordForm() {
      this.$refs['form2'].validate(valid => {
        if (valid) {
          addChangeRecord(this.form2).then(res => {
            let response = res.data;
            if (response.success) {
              this.$message.success(this.$t('datacenter.tips.saveSuccess'))
              this.getChangeRecordList()
            } else {
              this.$message.error(this.$t('datacenter.tips.saveError'))
            }
            this.record.open = false
          })
        }
      })
    }
  }
}
</script>
